.optionWrapper {
  color: white;
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: transparent;
  padding: 8px 16px;
  height: 100%;
  width: 100%;
  cursor: pointer;

  img {
    width: 16px;
    height: 16px;
    border-radius: 4px;
  }
}
