.label {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
}

.inputContainer {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 16px;
  width: 308px;
  height: 52px;
  background: #ffffff;
  border: 1px solid #d0d7de;
  border-radius: 4px;
  margin: 8px 0 16px 0;

  &:hover,
  &:active {
    border-color: #2f5fbb;
  }

  .input {
    font-size: 16px;
    line-height: 21px;
    text-align: right;
    letter-spacing: 0.02em;
    color: var(--primary);
    width: 100%;
    height: 21px;
    background: transparent;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .symbolContainer {
    max-width: 63px;
    height: 28px;
    padding: 6px 8px;
    background: #e9ecf3;
    box-shadow: 0px 4px 13px 1px rgba(0, 0, 0, 0.08);
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;

    p {
      font-size: 12px;
      line-height: 16px;
      text-transform: uppercase;
    }
  }
}
