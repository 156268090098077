.presaleWrapper {
  width: 744px;
  height: 323px;
  background: #ffffff;
  border-radius: 8px;
  margin: 123px auto 223px auto;
  padding: 72px 56px;
  text-align: center;

  h4 {
    margin-bottom: 56px;
  }
}

@media screen and (max-width: 576px) {
  .presaleWrapper {
    width: 100%;
    height: 206px;
    padding: 40px 24px;
    margin: 0;
    margin-bottom: 80px;

    h4 {
      margin-bottom: 32px;
    }
  }
}
