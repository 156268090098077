.modalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(15, 17, 56, 0.18);
  backdrop-filter: blur(1px);
  display: flex;
  justify-content: center;
  align-items: center;

  .modalContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 24px;
    width: 453px;
    height: 254px;
    background: #ffffff;
    box-shadow: 0px 4px 13px 1px rgba(0, 0, 0, 0.08);
    border-radius: 8px;

    @media screen and (max-width: 576px) {
      width: calc(100% - 32px);
    }
  }
}
