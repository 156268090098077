.topSection {
  padding-top: 56px;
  padding-bottom: 40px;
  @media screen and (max-width: 576px) {
    padding-top: unset;
    padding-bottom: 32px;
    p {
      font-size: 14px;
    }
    h3 {
      font-size: 24px;
    }
  }
}

.nftWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.cardsContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
