.nftContainer {
  width: 100%;
  min-height: 129px;
  padding: 24px;
  background: #ffffff;
  border-radius: 8px;
  color: var(--primary);

  @media screen and (max-width: 576px) {
    h4 {
      font-size: 20px;
    }
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;

    @media screen and (max-width: 576px) {
      flex-direction: column;
      align-items: start;
      justify-content: unset;
      padding-bottom: 16px;
      border-bottom: 1px solid #d0d7de;

      &:last-child {
        padding-bottom: unset;
        border-bottom: unset;
      }
    }

    .nftDataContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        &:first-child {
          width: 330px;
          margin-right: 24px;
        }
      }

      span {
        font-weight: 700;
        margin-right: 8px;
      }

      @media screen and (max-width: 576px) {
        flex-direction: column;
        align-items: start;
        justify-content: unset;

        p {
          font-size: 14px;
          margin-right: unset !important;
          width: 310px !important;
        }
      }
    }

    .buttonsContainer {
      width: 261px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media screen and (max-width: 576px) {
        width: 310px;
        justify-content: end;
        gap: 8px;
        margin-top: 24px;

        button {
          width: 88px !important;
          white-space: nowrap;
          padding: unset !important;
        }
      }

      button {
        width: 115px;
        height: 34px;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.02em;
        color: #2f5fbb;
        background-color: #fff;
        padding: 8px 16px;
        border: 1px solid #2f5fbb;
        border-radius: 3px;
      }

      .transferBtn {
        border: none;
      }

      .toIdoBtn,
      .transferBtn {
        &:hover {
          background-color: #3b73e0;
          color: #f7f7f7;
        }
        &:active {
          background-color: #2f5fbb;
          color: #f7f7f7;
        }
      }

      .claimBtn {
        color: #f7f7f7;
        background-color: #2f5fbb;
        border: none;
      }
    }
  }
  .transferInput {
    padding: 12px 16px;
    width: 405px;
    height: 45px;
    margin: 24px 0 24px 0;

    background: #ffffff;
    border: 1px solid #d0d7de;
    border-radius: 4px;

    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.02em;

    &::placeholder {
      color: #6c6e78;
    }
    @media screen and (max-width: 576px) {
      width: 100%;
    }
  }

  .modalButtonsContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      padding: 16px 24px;

      width: 190.5px;
      height: 50px;

      background: #2f5fbb;
      border-radius: 4px;

      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.02em;
      color: #ffffff;

      &:first-child {
        background: #ffffff;
        color: #2f5fbb;
        border: 1px solid #2f5fbb;

        &:hover {
          background-color: #3b73e0;
          color: #f7f7f7;
        }
        &:active {
          background-color: #2f5fbb;
          color: #f7f7f7;
        }
      }
    }
  }
}
