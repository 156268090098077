.footer {
  width: 100%;
  height: 128px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 154px;

  background: #ffffff;
  border-bottom: 1px solid #e3e7ee;
  box-shadow: -2px 2px 15px rgba(0, 0, 0, 0.09);
  margin-top: 300px;
}

@media screen and (max-width: 576px) {
  .footer {
    margin-top: 300px;
    height: 153px;
    padding: 16px;
    flex-direction: column;
  }
}
